import "./index.css";
import Router from "./Router";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const vRoot = ReactDOM.createRoot( document.getElementById( "iRoot" ) );

vRoot.render(
  <BrowserRouter>
    <Router />
  </BrowserRouter>
);
