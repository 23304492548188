import React, { useEffect } from 'react';
import axios from 'axios';
import 'toastr/build/toastr.min.css';
import toastr from "toastr";
import { vAPIURL } from '../Global';

const SignOut = () => {
  const FSignOut = async () => {
    let vStatus = 1;
    let vMessage = [];

    try{
        const vURL = vAPIURL + "/Sign/Out";
        const vData = {
            Action: {
              Value: "Out",
            },
        };
        const vConfig = {
          headers: {
            "Content-Type": "application/json",
            Authentication: `${localStorage.getItem("Authentication")}`,
          },
        };

        await axios
          .post(vURL, vData, vConfig)
          .then(
            (vResponse) => {
              vStatus = vResponse.data.Status;

              for(var i = 0; i < vResponse.data.Message.length; i++){
                vMessage.push(vResponse.data.Message[i]);
              }
            }
          )
          .catch(
            (vError) => {
              vStatus = 2;
              vMessage.push("Server Problem");
            }
          )
    }
    catch(vError){
      vStatus = 2;
      vMessage.push("Server Problem");
    }

    if(vMessage.length > 0){
      if( vStatus === 1 ){
				toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
    }
    localStorage.clear("Authentication");
		window.location.replace("/Sign/In");
  };

  useEffect(
		() => {
			FSignOut();
		}
		, []
	)


  return (
    <>
    </>
  )
};

export default SignOut;
