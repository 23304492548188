import React, { useState, useEffect } from "react";
import "toastr/build/toastr.min.css";
import axios from "axios";
import toastr from "toastr";
import { vAPIURL } from '../../Global';

const GolfCourseSearch = () => {
  const [vLoading, SetLoading] = useState(false);
  const [vData, SetData] = useState([]);
  const [vTotalPage, SetTotalPage] = useState(0);
  const [vNameValue, SetNameValue] = useState();
  const [vDescriptionValue, SetDescriptionValue] = useState();
  const [vStatusIdValue, SetStatusIdValue] = useState();
  const [vStatusId, SetStatusId] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const FGolfCourseSearch = async (vPage) => {
    SetLoading(true);
    let vStatus = 1;
    let vMessage = [];

    try {
      const vURL = vAPIURL + "/GolfCourse/Search";
      const vData = {
        Action: {
          Value: "Search",
        },
        Input: {
          Page: {
            Value: vPage,
          },
          Name: {
            Value: vNameValue,
          },
          Description: {
            Value: vDescriptionValue,
          },
          StatusId: {
            Value: vStatusIdValue,
          },
        },
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
      };
      await axios
        .post(vURL, vData, vConfig)
        .then((vResponse) => {
          vStatus = vResponse.data.Status;

          for (var i = 0; i < vResponse.data.Message.length; i++) {
            vMessage.push(vResponse.data.Message[i]);
          }
          if (vStatus === 1) {
            SetData(vResponse.data.Output.Result);
            SetTotalPage(vResponse.data.Output.TotalPage);
            SetNameValue(vResponse.data.Input.Name.Value);
            SetDescriptionValue(vResponse.data.Input.Description.Value);
            SetStatusIdValue(vResponse.data.Input.StatusId.Value);
            SetStatusId(vResponse.data.Input.StatusId);
          }
        })
        .catch((vError) => {
          vStatus = 2;
          vMessage.push("Server Problem");
        });
    } catch (vError) {
      vStatus = 2;
      vMessage.push("Server Problem");
    }
    if (vMessage.length > 0) {
      if (vStatus === 1) {
        //toastr.success( vMessage.join( "</br>" ) );
      } else {
        toastr.error(vMessage.join("</br>"));
      }
    }
    SetLoading(false);
    setCurrentPage(vPage);
  };

  // function IntToArray(vCount) {
  //   let IntToArray = [];
  //   for (let i = 1; i <= vCount; i++) IntToArray.push(i);
  //   return IntToArray;
  // }

  useEffect(() => {
    FGolfCourseSearch(1);
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Search</h3>
          </div>
          <div className="card-body">
            <table>
              <tr>
                <td>
                  <label htmlFor="startDate">
                    Name
                    &nbsp;
                  </label>
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={vNameValue}
                    onChange={(e) => SetNameValue(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="startDate">
                    Description
                    &nbsp;
                  </label>
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={vDescriptionValue}
                    onChange={(e) => SetDescriptionValue(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="startDate">
                    Status
                    &nbsp;
                  </label>
                </td>
                <td>
                  <select
                    value={vStatusIdValue}
                    onChange={(e) => SetStatusIdValue(e.target.value)}
                  >
                    {vStatusId?.List.map( ( vStatusIdValueTemp, index ) => (
                      <option key={index} value={vStatusIdValueTemp}>
                        {vStatusId?.ListCode[index]}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            </table>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col-md-6">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => {
                    FGolfCourseSearch(1);
                  }}
                >
                  {vLoading ? (
                    <center>
                      <i className="fas fa-1x fa-sync-alt fa-spin"></i>
                    </center>
                  ) : (
                    "Search"
                  )}
                </button>
              </div>
              <div className="col-md-6">
                <button
                  type="submit"
                  className="btn btn-primary float-right"
                  onClick={() => window.location.replace("/GolfCourse/0")}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        {vLoading ? (
          <div className="card">
            <div className="card-body">
              <center>
                <i className="fas fa-1x fa-sync-alt fa-spin"></i>
              </center>
            </div>
          </div>
        ) : (
          <div className="card">
            <div className="card-body">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Short Name</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Note</th>
                    <th>Status</th>
                    <th style={{ width: 30 }}>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {vData.map((vDataTemp, index) => (
                    <tr key={index}>
                      <td>{vDataTemp.No}</td>
                      <td>{vDataTemp.ShortName}</td>
                      <td>{vDataTemp.Name}</td>
                      <td>{vDataTemp.Description}</td>
                      <td>{vDataTemp.Note}</td>
                      <td>{vDataTemp.Status}</td>
                      <td>
                        <button
                          className="page-link ms-1"
                          onClick={(e) =>
                            window.location.replace(
                              "/GolfCourse/" + vDataTemp.Id
                            )
                          }
                        >
                          <center>
                            <i className="fas fa-search"></i>
                          </center>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <ul className="pagination pagination-sm m-0 float-right">
                <button
                  className="page-link"
                  onClick={() => FGolfCourseSearch(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  &laquo;
                </button>
                {Array.from({ length: 5 }).map((_, index) => {
                  let pageNumber;
                  if (vTotalPage <= 5) {
                    pageNumber = index + 1;
                  } else {
                    if (currentPage <= 2) {
                      pageNumber = index + 1;
                    } else if (currentPage >= vTotalPage - 2) {
                      pageNumber = vTotalPage - 4 + index;
                    } else {
                      pageNumber = currentPage + index - 2;
                    }
                  }

                  if (pageNumber >= 1 && pageNumber <= vTotalPage) {
                    return (
                      <li
                        className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
                        key={index}
                      >
                        <button
                          className="page-link"
                          onClick={() => FGolfCourseSearch(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
                <button
                  className="page-link"
                  onClick={() => FGolfCourseSearch(currentPage + 1)}
                  disabled={currentPage === vTotalPage}
                >
                  &raquo;
                </button>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GolfCourseSearch;
