import React, { useState, useEffect } from "react";
import axios from "axios";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { vAPIURL } from "../../Global";
import moment from "moment";
import DateTimePicker from "react-datetime-picker";
import TimePicker from "react-time-picker";

const TeeTimeSearch = () => {
  const [selectedId, SetselectedId] = useState([]);
  const [vLoading, SetLoading] = useState(false);
  const [vStartDateValue, SetStartDateValue] = useState();
  const [vEndDateValue, SetEndDateValue] = useState();
  const [vStatusIdValue, SetStatusIdValue] = useState();
  const [vStatusId, SetStatusId] = useState();
  const [vResult, SetResult] = useState([]);
  const [vTotalPageValue, SetTotalPageValue] = useState(0);
  const [vTimeType, SetTimeType] = useState();
  const [vTimeTypeValue, SetTimeTypeValue] = useState();
  const [vDescriptionValue, SetDescriptionValue] = useState();
  const [vTeeTime, SetTeeTimeValue] = useState();
  const [vActionList, SetActionList] = useState([]);
  const [vInActives, SetInActive] = useState([]);
  const [vActives, SetvActives] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [vGolfCourseIdValue, SetvGolfCourseIdValue] = useState();
  const [vGolfCourseId, SetvGolfCourseId] = useState();

  const FTeeTimeSearch = async (vPage) => {
    SetLoading(true);
    let vStatus = 1;
    let vMessage = [];

    try {
      const vURL = vAPIURL + "/TeeTime/Search";
      const vData = {
        Action: {
          Value: "Search", //serverproblem bila diganti dengan vAction
        },
        Input: {
          Page: {
            Value: vPage,
          },
          StartDate: {
            Value: vStartDateValue,
          },
          EndDate: {
            Value: vEndDateValue,
          },
          StatusId: {
            Value: vStatusIdValue,
          },
          TimeType: {
            Value: vTimeTypeValue,
          },
          Description: {
            Value: vDescriptionValue,
          },
          TeeTime: {
            Value: vTeeTime,
          },
          GolfCourse: {
            Value: vGolfCourseIdValue,
          },
        },
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
      };
      await axios
        .post(vURL, vData, vConfig)
        .then((vResponse) => {
          vStatus = vResponse.data.Status;

          for (var i = 0; i < vResponse.data.Message.length; i++) {
            vMessage.push(vResponse.data.Message[i]);
          }

          if (vStatus === 1) {
            SetResult(vResponse.data.Output.Result);
            SetTotalPageValue(vResponse.data.Output.TotalPage);
            SetStartDateValue(vResponse.data.Input.StartDate.Value);
            SetEndDateValue(vResponse.data.Input.EndDate.Value);
            SetStatusIdValue(vResponse.data.Input.StatusId.Value);
            SetStatusId(vResponse.data.Input.StatusId);
            SetTimeTypeValue(vResponse.data.Input.TimeType.Value);
            SetTimeType(vResponse.data.Input.TimeType);
            SetDescriptionValue(vResponse.data.Input.Description.Value);
            SetTeeTimeValue(vResponse.data.Input.TeeTime.Value);
            SetActionList(vResponse.data.Action.List);
            SetvGolfCourseIdValue(vResponse.data.Input.GolfCourse.Value);
            SetvGolfCourseId(vResponse.data.Input.GolfCourse);
          }
        })
        .catch((vError) => {
          vStatus = 2;
          vMessage.push("Server Problem");
        });
    } catch (vError) {
      vStatus = 2;
      vMessage.push("Server Problem");
    }
    if (vMessage.length > 0) {
      if (vStatus === 1) {
        //toastr.success( vMessage.join( "</br>" ) );
      } else {
        toastr.error(vMessage.join("</br>"));
      }
    }
    SetLoading(false);
    setCurrentPage(vPage);
  };

  const vInActive = async () => {
    SetLoading(true);
    let vStatus = 1;
    let vMessage = [];

    try {
      const vURL = vAPIURL + "/TeeTime/Search";
      const vData = {
        Action: {
          Value: "Inactive",
        },
        Input: {
          TeeTimeId: {
            Value: selectedId,
          },
        },
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
      };
      await axios
        .post(vURL, vData, vConfig)
        .then((vResponse) => {
          vStatus = vResponse.data.Status;

          for (var i = 0; i < vResponse.data.Message.length; i++) {
            vMessage.push(vResponse.data.Message[i]);
          }
          SetInActive(vResponse.data.Action.List);
          SetselectedId(selectedId);
        })
        .catch((vError) => {
          vStatus = 2;
          vMessage.push("Server Problem");
        });
    } catch (vError) {
      vStatus = 2;
      vMessage.push("Server Problem");
    }

    if (vMessage.length > 0) {
      if (vStatus === 1) {
        toastr.success(vMessage.join("</br>"));
        window.location.replace("/TeeTime/Search");
      } else {
        toastr.error(vMessage.join("</br>"));
      }
    }

    SetLoading(false);
  };


  const vActive = async () => {
    SetLoading(true);
    let vStatus = 1;
    let vMessage = [];

    try {
      const vURL = vAPIURL + "/TeeTime/Search";
      const vData = {
        Action: {
          Value: "Active",
        },
        Input: {
          TeeTimeId: {
            Value: selectedId,
          },
        },
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
      };
      await axios
        .post(vURL, vData, vConfig)
        .then((vResponse) => {
          vStatus = vResponse.data.Status;

          for (var i = 0; i < vResponse.data.Message.length; i++) {
            vMessage.push(vResponse.data.Message[i]);
          }
          SetvActives(vResponse.data.Action.List);
          SetselectedId(selectedId);
        })
        .catch((vError) => {
          vStatus = 2;
          vMessage.push("Server Problem");
        });
    } catch (vError) {
      vStatus = 2;
      vMessage.push("Server Problem");
    }

    if (vMessage.length > 0) {
      if (vStatus === 1) {
        toastr.success(vMessage.join("</br>"));
        window.location.replace("/TeeTime/Search");
      } else {
        toastr.error(vMessage.join("</br>"));
      }
    }

    SetLoading(false);
  };

  //handling untuk menampung value id yang akan di kirim ke actionlist inactive, active, reserved
  // const handleCheckboxChange = (e) => {
  //   const id = e.target.value;
  //   if (e.target.checked) {
  //     SetselectedId([...selectedId, id]);
  //   } else {
  //     SetselectedId(selectedId.filter((selectedId) => selectedId !== id));
  //   }
  // };
  //handling untuk menampung value id yang akan di kirim ke actionlist inactive, active, reserved

  // function IntToArray(vCount) {
  //   let IntToArray = [];
  //   for (let i = 1; i <= vCount; i++) IntToArray.push(i);
  //   return IntToArray;
  // }

  useEffect(() => {
    FTeeTimeSearch(1);
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Search</h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <table>
                  <tr>
                    <td>
                      <label htmlFor="startDate">Start Date &nbsp;</label>
                    </td>
                    <td>
                      <DateTimePicker
                        value={
                          vStartDateValue
                            ? moment(vStartDateValue, "DD-MMM-YYYY").toDate()
                            : null
                        }
                        onChange={(e) =>
                          SetStartDateValue(moment(e).format("DD-MMM-YYYY"))
                        }
                        format="dd-MMM-y"
                        clearIcon={null}
                        data-select={false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor="startDate">End Date &nbsp;</label>
                    </td>
                    <td>
                      <DateTimePicker
                        value={
                          vEndDateValue
                            ? moment(vEndDateValue, "DD-MMM-YYYY").toDate()
                            : null
                        }
                        onChange={(e) =>
                          SetEndDateValue(moment(e).format("DD-MMM-YYYY"))
                        }
                        format="dd-MMM-y"
                        clearIcon={null}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor="GolfCourse">GolfCourse</label>
                    </td>
                    <td>
                      <select
                        id="GolfCourse"
                        value={vGolfCourseIdValue}
                        onChange={(e) => SetvGolfCourseIdValue(e.target.value)}
                      >
                        {vGolfCourseId?.List.map((vGolfCouseIdValueTemp, index) => (
                          <option
                            key={index} value={vGolfCouseIdValueTemp}
                          >
                            {vGolfCourseId?.ListCode[index]}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="col-md-4">
                <table>
                  
                  <tr>
                    <td>
                      <label htmlFor="Tee Time">Tee Time &nbsp;</label>
                    </td>
                    <td>
                      <TimePicker
                        value={vTeeTime}
                        onChange={SetTeeTimeValue}
                        format={"HH:mm"}
                        clearIcon={null}
                        clockIcon={null}
                        disableClock={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor="Time Type">Time Type</label>
                    </td>
                    <td>
                      <select
                        id="vTimeType"
                        value={vTimeTypeValue}
                        onChange={(e) => SetTimeTypeValue(e.target.value)}
                      >
                        {vTimeType?.List.map((vTimeTypeValueTemp, index) => (
                          <option key={index} value={vTimeTypeValueTemp}>
                            {vTimeType?.ListCode[index]}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor="startDate">Status &nbsp;</label>
                    </td>
                    <td>
                      <select
                        id="statusId"
                        value={vStatusIdValue}
                        onChange={(e) => SetStatusIdValue(e.target.value)}
                      >
                        {vStatusId?.List.map((vStatusIdValueTemp, index) => (
                          <option key={index} value={vStatusIdValueTemp}>
                            {vStatusId?.ListCode[index]}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="col-md-4">
                <table>
                  <tr>
                      <td>
                        <label htmlFor="Desc">Description &nbsp;</label>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => SetDescriptionValue(e.target.value)}
                          style={{ width: 153, height: 30 }}
                        />
                      </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col">
                {vLoading ? (
                  <center>
                    <i className="fas fa-1x fa-sync-alt fa-spin ms-3"></i>
                  </center>
                ) : (
                  <>
                    {vActionList
                      .map((vActionListTemp, index) => (
                        <React.Fragment key={index}>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={() => {
                              FTeeTimeSearch(1);
                            }}
                          >
                            {vActionListTemp}
                          </button>
                          &nbsp;
                        </React.Fragment>
                      ))
                      .slice(0, 1)}
                    {vActionList
                      .map((vActionListTemp, index) => (
                        <React.Fragment key={index}>
                          <button
                            value={vActives}
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => vActive(vActionListTemp)}
                            style={{backgroundColor: "#46FC5E",
                              border: "none",
                              color: "black"
                            }}
                          >
                            {vActionListTemp}
                          </button>
                          &nbsp;
                        </React.Fragment>
                      ))
                      .slice(1,-2)}
                      {vActionList
                      .map((vActionListTemp, index) => (
                        <React.Fragment key={index}>
                          <button
                            value={vInActives}
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => vInActive(vActionListTemp)}
                            style={{backgroundColor: "#FC4646",
                              border: "none",
                              color: "black"
                            }}
                          >
                            {vActionListTemp}
                          </button>
                          &nbsp;
                        </React.Fragment>
                      ))
                      .slice(2, -1)}
                  </>
                )}
              </div>
              <div className="col-md-auto">
                {!vLoading && (
                  <button
                    type="submit"
                    className="btn btn-primary float-right"
                    onClick={() =>
                      window.location.replace("/TeeTime/GenerateByInterval")
                    }
                  >
                    Create Tee Time
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        {vLoading ? (
          <div className="card">
            <div className="card-body">
              <center>
                <i className="fas fa-1x fa-sync-alt fa-spin me-1"></i>
              </center>
            </div>
          </div>
        ) : (
          <div className="card">
            <div className="card-body">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <div className="d-flex justify-content-center">
                      <center>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={(e) => {
                            setSelectAll(e.target.checked);
                            if (e.target.checked) {
                              const ids = vResult.map((item) => item.Id);
                              SetselectedId(ids);
                            } else {
                              SetselectedId([]);
                            }
                          }}
                        />
                      </center>
                    </div>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      No
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Golf Course
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Description
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Price
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Publish Rate
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Price Foreign
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Foreign Publish Rate
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Status
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Tee Date
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Tee Time
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Time Type
                    </th>
                    <th style={{ width: 30 }}>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {vResult.map((vResulTemp, index) => (
                    <tr key={index}>
                      <div className="d-flex justify-content-center align-items-center">
                        <center>
                          <input
                            type="checkbox"
                            value={vResulTemp.Id}
                            checked={selectedId.includes(vResulTemp.Id)}
                            onChange={(e) => {
                              const id = e.target.value;
                              if (e.target.checked) {
                                SetselectedId([...selectedId, id]);
                              } else {
                                SetselectedId(
                                  selectedId.filter((item) => item !== id)
                                );
                              }
                            }}
                          />
                        </center>
                      </div>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {vResulTemp.No}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {vResulTemp.Green}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {vResulTemp.Description}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        &nbsp; &nbsp;
                        {vResulTemp.Price}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        &nbsp; &nbsp;
                        {vResulTemp.PriceUp}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        &nbsp; &nbsp;
                        {vResulTemp.PriceForeign}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        &nbsp; &nbsp;
                        {vResulTemp.PriceForeignUp}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {vResulTemp.Status}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {vResulTemp.TeeDate}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {vResulTemp.TeeTime}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {vResulTemp.TimeType}
                      </td>
                      <td>
                        <button
                          className="page-link ms-1"
                          onClick={(e) =>
                            window.location.replace("/TeeTime/" + vResulTemp.Id)
                          }
                        >
                          <center>
                            <i className="fas fa-search"></i>
                          </center>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <ul className="pagination pagination-sm m-0 float-right">
                <button
                  className="page-link"
                  onClick={() => FTeeTimeSearch(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  &laquo;
                </button>
                {Array.from({ length: 5 }).map((_, index) => {
                  let pageNumber;
                  if (vTotalPageValue <= 5) {
                    pageNumber = index + 1;
                  } else {
                    if (currentPage <= 2) {
                      pageNumber = index + 1;
                    } else if (currentPage >= vTotalPageValue - 2) {
                      pageNumber = vTotalPageValue - 4 + index;
                    } else {
                      pageNumber = currentPage + index - 2;
                    }
                  }

                  if (pageNumber >= 1 && pageNumber <= vTotalPageValue) {
                    return (
                      <li
                        className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
                        key={index}
                      >
                        <button
                          className="page-link"
                          onClick={() => FTeeTimeSearch(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
                <button
                  className="page-link"
                  onClick={() => FTeeTimeSearch(currentPage + 1)}
                  disabled={currentPage === vTotalPageValue}
                >
                  &raquo;
                </button>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeeTimeSearch;
