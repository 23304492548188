import React, { useState, useEffect } from "react";
import axios from "axios";
import toastr from "toastr";
import moment from "moment";
import DateTimePicker from "react-datetime-picker";
import TimePicker from "react-time-picker";
import { vAPIURL } from "../../Global";

const TeeGBYI = () => {
  const [vLoading, SetLoading] = useState(false);
  const [vActionList, SetActionList] = useState([]);
  const [vGolfGreenId, SetGolfGreenId] = useState("");
  const [vGolfGreenIdList, SetGolfGreenIdList] = useState([]); //menyimpan list dropdown
  const [vGolfGreenIdListCode, SetGolfGreenIdListCode] = useState([]);
  const [vTeeDateValue, SetTeeDateValue] = useState();
  const [vStartTeeTimeValue, SetStartTeeTimeValue] = useState();
  const [vEndTeeTimeValue, SetEndTeeTimeValue] = useState();
  
  const [vPriceValue, SetPriceValue] = useState("");
  const [vPriceRegex, SetPriceRegex] = useState("");
  const [vPriceFormat, SetPriceFormat] = useState("");

  const [vPriceUpValue, SetPriceUpValue] = useState("");
  const [vPriceUpRegex, SetPriceUpRegex] = useState("");
  const [vPriceUpFormat, SetPriceUpFormat] = useState("");

  const [vPriceForeignValue, SetPriceForeignValue] = useState("");
  
  const [vPriceForeignUpValue, SetPriceForeignUpValue] = useState("");

  const [vDescriptionValue, SetDescriptionValue] = useState("");
  const [vIntervalValue, SetIntervalValue] = useState("");
  const [, SetTimeRegex] = useState("");

  const FTeeGBYI = async (vAction) => {
    SetLoading(true);
    let vStatus = 1;
    let vMessage = [];

    try {
      const vURL = vAPIURL + "/TeeTime/GenerateByInterval";
      const vData = {
        Action: {
          Value: vAction,
        },
        Input: {
          GolfGreenId: {
            Value: vGolfGreenId,
          },
          TeeDate: {
            Value: vTeeDateValue,
          },
          StartTeeTime: {
            Value: vStartTeeTimeValue,
          },
          EndTeeTime: {
            Value: vEndTeeTimeValue,
          },
          Interval: {
            Value: vIntervalValue,
          },
          Price: {
            Value: vPriceValue,
          },
          PriceUp: {
            Value: vPriceUpValue,
          },
          PriceForeign: {
            Value: vPriceForeignValue,
          },
          PriceForeignUp: {
            Value: vPriceForeignUpValue,
          },
          Description: {
            Value: vDescriptionValue,
          },
        },
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
      };

      await axios
        .post(vURL, vData, vConfig)
        .then((vResponse) => {
          vStatus = vResponse.data.Status;

          for (var i = 0; i < vResponse.data.Message.length; i++) {
            vMessage.push(vResponse.data.Message[i]);
          }

          SetActionList(vResponse.data.Action.List);

          SetGolfGreenId(vResponse.data.Input.GolfGreenId.Value);
          SetGolfGreenIdList(vResponse.data.Input.GolfGreenId.List);
          SetGolfGreenIdListCode(vResponse.data.Input.GolfGreenId.ListCode);
          SetTeeDateValue(vResponse.data.Input.TeeDate.Value);
          SetStartTeeTimeValue(vResponse.data.Input.StartTeeTime.Value);
          SetEndTeeTimeValue(vResponse.data.Input.EndTeeTime.Value);
          
          SetPriceValue(vResponse.data.Input.Price.Value);
          SetPriceRegex(vResponse.data.Input.Price.Regex);
          SetPriceFormat(vResponse.data.Input.Price.Format);
          
          SetPriceUpValue(vResponse.data.Input.PriceUp.Value);
          SetPriceUpRegex(vResponse.data.Input.PriceUp.Regex);
          SetPriceUpFormat(vResponse.data.Input.PriceUp.Format);
          
          SetPriceForeignValue(vResponse.data.Input.PriceForeign.Value);
          
          SetPriceForeignUpValue(vResponse.data.Input.PriceForeignUp.Value);
          
          SetDescriptionValue(vResponse.data.Input.Description.Value);
          SetIntervalValue(vResponse.data.Input.Interval.Value);
          SetTimeRegex(vResponse.data.Input.StartTeeTime.Regex);
        })
        .catch((vError) => {
          vStatus = 2;
          vMessage.push("Server Problem");
          console.log(vError);
        });
    } catch (vError) {
      vStatus = 2;
      vMessage.push("Server Problem");
      console.log(vError);
    }

    if (vMessage.length > 0) {
      if (vStatus === 1) {
        toastr.success(vMessage.join("</br>"));
        // window.location.replace("/TeeTime/Search");
      } else {
        toastr.error(vMessage.join("</br>"));
      }
    }

    if( vStatus === 1 && vAction === "Create" ){
      window.location.replace('/TeeTime/Search');
    }

    SetLoading(false);
  };

  useEffect(() => {
    FTeeGBYI("");
  }, []); //kalau ditambah ke dalam dependency FTeeTime malah ga bisa ubah field price & priceforeign

  // start commas handler
  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");
  // end commas handler

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <table>
              <tr>
                <td>
                  <label>
                    Golf Course
                  </label>
                </td>
                <td style={{whiteSpace:"nowrap"}}>
                  <select
                    value={vGolfGreenId}
                    onChange={(e) => SetGolfGreenId(e.target.value)}
                  >
                    {vGolfGreenIdListCode.map((optionValue, index) => (
                      <option key={index} value={vGolfGreenIdList[index]}>
                        {optionValue}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Tee Date
                  </label>
                </td>
                <td style={{whiteSpace:"nowrap"}}>
                  <DateTimePicker
                    value={
                      vTeeDateValue
                        ? moment(vTeeDateValue, "DD-MMM-YYYY").toDate()
                        : null
                    }
                    format="dd-MMM-y"
                    onChange={(e) =>
                      SetTeeDateValue(moment(e).format("DD-MMM-YYYY"))
                    }
                    clearIcon={null}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Start Tee Time
                  </label>
                </td>
                <td>
                  <TimePicker
                    format={"HH:mm"}
                    value={vStartTeeTimeValue}
                    onChange={SetStartTeeTimeValue}
                    clearIcon={null}
                    clockIcon={null}
                    disableClock={true}
                  />
                  &nbsp;
                  <label
                    style={{marginBottom:"unset"}}
                  >
                    End Tee Time
                  </label>
                  <TimePicker
                    format={"HH:mm"}
                    className="ms-1"
                    value={vEndTeeTimeValue}
                    onChange={SetEndTeeTimeValue}
                    clearIcon={null}
                    clockIcon={null}
                    disableClock={true}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Interval
                  </label>
                </td>
                <td>
                  <input
                    type="number"
                    value={vIntervalValue}
                    onChange={(e) => SetIntervalValue(e.target.value)}
                    style={{width: "50px"}}
                  />
                </td>
              </tr>
            </table>
            <div className="form-group">
              <label>Price</label>
              <input
                type="text"
                className="form-control"
                value={vPriceValue}
                onChange={(e) => SetPriceValue(addCommas(removeNonNumeric(e.target.value)))}
                pattern={`(${vPriceRegex})|(${vPriceFormat})`}
              />
            </div>
            <div className="form-group">
              <label>Publish Rate</label>
              <input
                type="text"
                className="form-control"
                value={vPriceUpValue}
                onChange={(e) => SetPriceUpValue(addCommas(removeNonNumeric(e.target.value)))}
                pattern={`(${vPriceUpRegex})|(${vPriceUpFormat})`}
              />
            </div>
            <div className="form-group">
              <label>Price Foreign</label>
              <input
                type="text"
                className="form-control"
                value={vPriceForeignValue}
                onChange={(e) => SetPriceForeignValue(addCommas(removeNonNumeric(e.target.value)))}
                pattern={`(${vPriceRegex})|(${vPriceFormat})`}
              />
            </div>
            <div className="form-group">
              <label>Foreign Publish Rate</label>
              <input
                type="text"
                className="form-control"
                value={vPriceForeignUpValue}
                onChange={(e) => SetPriceForeignUpValue(addCommas(removeNonNumeric(e.target.value)))}
                pattern={`(${vPriceUpRegex})|(${vPriceUpFormat})`}
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                type="text"
                className="form-control"
                value={vDescriptionValue}
                onChange={(e) => SetDescriptionValue(e.target.value)}
              />
            </div>
          </div>
          <div className="card-footer">
            {vLoading ? (
              <center>
                <i className="fas fa-1x fa-sync-alt fa-spin"></i>
              </center>
            ) : (
              vActionList.map((vActionListTemp, index) => (
                <>
                  <button
                    key={index}
                    className="btn btn-primary"
                    type="submit"
                    onClick={() => FTeeGBYI(vActionListTemp)}
                  >
                    {vActionListTemp}
                  </button>
                  &nbsp;
                </>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeeGBYI;
