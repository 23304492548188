import React, { useState, useCallback, useEffect } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { vAPIURL } from "../../Global";
import axios from "axios";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import JoditEditor from "jodit-react";

const ConfirmationBooking = () => {
  
  const [vLoading, SetLoading] = useState(false);
  const [vEdit, SetEdit] = useState("");
  const [vName, SetName] = useState("");
  const [vEmail, SetEmail] = useState("");
  const [vEmailCC, SetEmailCC] = useState("");
  const [vTemplate, SetTemplate] = useState("");
  const [cTemplate, SetcTemplate] = useState("");

  const FSendEmail = useCallback(async () => {
    SetLoading(true);
    let vStatus = 1;
    let vMessage = [];

    try {
      const vURL = vAPIURL + "/Email/Template";
      const vData = {
        Action: {
          Value: "Send",
        },
        Input: {
          Email: {
            Value: vEmail,
          },
          Name: {
            Value: vName,
          },
        },
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
      };
      await axios
        .post(vURL, vData, vConfig)
        .then((vResponse) => {
          vStatus = vResponse.data.Status;

          for (var i = 0; i < vResponse.data.Message.length; i++) {
            vMessage.push(vResponse.data.Message[i]);
          }
          if (vStatus === 1) {
            SetEdit(vResponse.data.Input.Value);
            SetName(vResponse.data.Input.Value);
          }
        })
        .catch((vError) => {
          vStatus = 2;
          vMessage.push("Server Problem");
        });
    } catch (vError) {
      vStatus = 2;
      vMessage.push("Server Problem");
    }

    if (vStatus === 1) {
      toastr.success("Email Success");
      window.location.replace("/ConfirmationBooking");
    } else {
      toastr.error("Email Error");
    }

    SetLoading(false);
  }, [vEmail, vName]);

  const FEditEmail = useCallback(async () => {
    SetLoading(true);
    let vStatus = 1;
    let vMessage = [];

    try {
      const vURL = vAPIURL + "/Email/Template";
      const vData = {
        Action: {
          Value: "Edit",
        },
        Input: {
          MailBCC: {
            Value: vEmailCC,
          },
          TermCondition: {
            Value: vTemplate,
          },
          MailFooter: {
            Value: cTemplate,
          },
        },
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
      };
      await axios
        .post(vURL, vData, vConfig)
        .then((vResponse) => {
          vStatus = vResponse.data.Status;
          for (var i = 0; i < vResponse.data.Message.length; i++) {
            vMessage.push(vResponse.data.Message[i]);
          }
          if (vStatus === 1) {
            SetTemplate(vResponse.data.Input.Value);
            SetcTemplate(vResponse.data.Input.Value);
            SetEmailCC(vResponse.data.Input.Value);
          }
        })
        .catch((vError) => {
          vStatus = 2;
          vMessage.push("Server Problem");
        });
    } catch (vError) {
      vStatus = 2;
      vMessage.push("Server Problem");
    }

    if (vStatus === 1) {
      toastr.success("Edit Success");
      window.location.replace("/ConfirmationBooking");
    } else {
      toastr.error("Edit Error");
    }

    SetLoading(false);
  }, [
    vTemplate, 
    cTemplate,
    vEmailCC
  ]);

  const FSearchMail = async () => {
    let vMessage = [];
    try {
      const vURL = vAPIURL + "/Email/Template";
      const vData = {
        Action: {
          Value: "Search",
        },
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
      };
      const vResponse = await axios.post(vURL, vData, vConfig);

      if (vResponse.data.Status === 1) {
        const vResult = vResponse.data.Output.Result[0];
        SetEmailCC(vResult.MailBCC);
        SetTemplate(vResult.TermCondition);
        SetcTemplate(vResult.MailFooter);
      }
    } catch (vError) {
      vMessage.push("Server Problem");
    }
  };

  useEffect(() => {
    FSearchMail();
  }, []);




  return (
    <>
      <Card>
        <Card.Header>Configuration Email</Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3" controlId="headerValue">
              <Form.Label>Email To</Form.Label>
              <Form.Control
                type="Email"
                value={vEmail}
                onChange={(e) => SetEmail(e.target.value)}
                style={{ height: "30px" }}
              />
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={vName}
                onChange={(e) => SetName(e.target.value)}
                style={{ height: "30px" }}
              />
              <Form.Label>Email BCC</Form.Label>
              <Form.Control
                type="Email"
                value={vEmailCC}
                onChange={(e) => SetEmailCC(e.target.value)}
                style={{ height: "30px" }}
              />
              <span className="text-muted">Please add ";" to use more BCC</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="cancelationValue">
              <Form.Label>Cancelation Policy </Form.Label>
              <JoditEditor
                type="text"
                value={vTemplate}
                onChange={newContent => SetTemplate(newContent)}
            />
            </Form.Group>
            <Form.Group className="mb-3" controlId="footerValue">
              <Form.Label>Footer</Form.Label>
              <JoditEditor
                type="text"
                value={cTemplate}
                onChange={newContent => SetcTemplate(newContent)}
              />
            </Form.Group>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Button
            variant="primary"
            type="submit"
            onClick={() => FEditEmail()}
            className="me-1"
            value={vEdit}
          >
            {vLoading ? (
              <center>
                <i className="fas fa-1x fa-sync-alt fa-spin"></i>
              </center>
            ) : (
              "Save"
            )}
          </Button>
          <Button variant="primary" type="submit" onClick={() => FSendEmail()}>
            {vLoading ? (
              <center>
                <i className="fas fa-1x fa-sync-alt fa-spin"></i>
              </center>
            ) : (
              "Send Email "
            )}
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default ConfirmationBooking;
