import 'toastr/build/toastr.min.css';
import axios from 'axios';
import React, { useState } from 'react';
import toastr from 'toastr';
import { vAPIURL } from '../Global';

const SignIn = () => {
  const [vLoading, SetLoading] = useState(false);
  const [vUsername, SetUsername] = useState('');
  const [vPassword, SetPassword] = useState('');

  const FSignIn = async () => {
    SetLoading(true);
    let vStatus = 1;
    let vMessage = [];

    try {
      const vURL = vAPIURL + "/Sign/In";
      const vData = {
        Action: {
          Value: 'In',
        },
        Input: {
          Username: {
            Value: vUsername,
          },
          Password: {
            Value: vPassword,
          },
        },
      };
      const vConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      await axios
        .post(vURL, vData, vConfig)
        .then((vResponse) => {
          vStatus = vResponse.data.Status;

          for (var i = 0; i < vResponse.data.Message.length; i++) {
            vMessage.push(vResponse.data.Message[i]);
          }

          if (vStatus === 1) {
            SetPassword('');
            localStorage.setItem( "Authentication", vResponse.data.Output.Authentication );
            window.location.replace('/');
          }
        })
        .catch((vError) => {
          vStatus = 2;
          vMessage.push('Server Problem');
        });
    } catch (vError) {
      vStatus = 2;
      vMessage.push('Server Problem');
    }

    if (vMessage.length > 0) {
      if (vStatus === 1) {
        toastr.success(vMessage.join('</br>'));
      } else {
        toastr.error(vMessage.join('</br>'));
      }
    }

    SetLoading(false);
  };

  return (
    <div
      className="login-box"
      align="center"
      style={{
        justifyContent: 'center',
        margin: '0 auto',
        alignItems: 'center',
        marginTop: 105,
      }}
    >
      <div className="login-logo">
        <b>GMC</b>Admin
      </div>
      <div className="card">
        <div className="card-body">
          <p className="login-box-msg">Sign In</p>
          <div className="input-group mb-3">
            <input type="input" className="form-control" placeholder="Username" onChange={(e) => SetUsername(e.target.value)} />
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-user" />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="password" className="form-control" placeholder="Password" onChange={(e) => SetPassword(e.target.value)} />
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-lock" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {vLoading ? (
                <button type="submit" className="btn btn-primary btn-block">
                  <center>
                    <i className="fas fa-1x fa-sync-alt fa-spin"></i>
                  </center>
                </button>
              ) : (
                <button type="submit" className="btn btn-primary btn-block" onClick={FSignIn}>
                  Sign In
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
