let vAPIURL = "";

switch( window.location.host.toLowerCase() ) {
    case "localhost:3000":
        // vAPIURL = "http://10.100.1.225/GMC_API/public/AdminGolf";
        vAPIURL = "https://apidev.gmc.gmclub.id/AdminGolf";
        break;
    case "localhost:3001":
        // vAPIURL = "http://10.100.1.225/GMC_API/public/AdminGolf";
        vAPIURL = "https://apidev.gmc.gmclub.id/AdminGolf";
        break;
    case "10.100.1.152:3000":
        // vAPIURL = "http://10.100.1.225/GMC_API/public/AdminGolf";
        vAPIURL = "https://apidev.gmc.gmclub.id/AdminGolf";
        break;
    case "10.100.1.152:3001":
        // vAPIURL = "http://10.100.1.225/GMC_API/public/AdminGolf";
        vAPIURL = "https://apidev.gmc.gmclub.id/AdminGolf";
        break;
    case "admingolfdev.gmc.gmclub.id":
        vAPIURL = "https://apidev.gmc.gmclub.id/AdminGolf";
        break;
    case "admingolf.gmc.gmclub.id":
        vAPIURL = "https://api.gmc.gmclub.id/AdminGolf";
        break;
    default:
        break
}

export { vAPIURL }
