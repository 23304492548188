import Layout from './Layout/index';
import GolfCourseSearch from './Module/GolfCourse/GolfCourseSearch';
import GolfCourse from './Module/GolfCourse/GolfCourse';
import TeeTimeSearch from './Module/TeeTime/TeeTimeSearch';
import TeeTime from './Module/TeeTime/TeeTime';
import TeeGBYI from './Module/TeeTime/TeeGBYI';
import BookingSearchForm from './Module/Booking/BookingSearch';
import Booking from './Module/Booking/Booking';
import ConfirmationBooking from './Module/Configuration/ConfirmationBooking';
import SignIn from './Module/SignIn';
import SignOut from './Module/SignOut';
import PasswordChange from './Module/PasswordChange';
import { Route, Routes, Navigate } from 'react-router-dom';

const Router = () => {
  var vAuthentication = '';

  if (localStorage.getItem('Authentication') !== null) {
    vAuthentication = localStorage.getItem('Authentication');
  }

  return (
    <Routes>
      <Route path="/" element={ (vAuthentication !== "") ? <Layout /> : <Navigate to="/Sign/In" /> } />
      <Route path="/Sign/In" element={ (vAuthentication === "") ? <SignIn /> : <Navigate to="/" />} />
      <Route path="/Sign/Out" element={ (vAuthentication !== "") ? <SignOut /> : <Navigate to="/" /> } />
      <Route path="/Password" element={ (vAuthentication !== "") ? <PasswordChange /> : <Navigate to="/" />} />
      <Route path="/GolfCourse/Search" element={ (vAuthentication !== "") ? <Layout><GolfCourseSearch /></Layout> : <Navigate to="/Sign/In" /> } />
      <Route path="/GolfCourse/:vId" element={ (vAuthentication !== "") ? <Layout><GolfCourse /></Layout> : <Navigate to="/" /> } />
      <Route path="/TeeTime/Search" element={ (vAuthentication !== "") ? <Layout><TeeTimeSearch /></Layout> : <Navigate to="/Sign/In" />} />
      <Route path="/TeeTime/:vId" element={ (vAuthentication !== "") ? <Layout><TeeTime /></Layout> : <Navigate to="/" /> } />
      <Route path="/TeeTime/GenerateByInterval" element={ (vAuthentication !== "") ? <Layout><TeeGBYI /></Layout> : <Navigate to="/" /> } />
      <Route path="/Booking/Search" element={ (vAuthentication !== "") ? <Layout><BookingSearchForm /></Layout> : <Navigate to="/Sign/In" /> } />
      <Route path="/Booking/:vId/DetailSearch" element={ (vAuthentication !== "") ? <Layout><Booking /></Layout> : <Navigate to="/" /> } />
      <Route path="/ConfirmationBooking" element={ (vAuthentication !== "") ? <Layout><ConfirmationBooking /></Layout> : <Navigate to="/" /> } />
    </Routes>
  );
};

export default Router;
