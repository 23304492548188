import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { vAPIURL } from "../Global";
import axios from "axios";

const Header = () => {

  const [vResult, SetResult] = useState([]);
  const FuserSearch = async ()=> {
    let vStatus = 1;
    let vMessage = [];
    try {
      const vURL = vAPIURL + "/UserInfo/Search";
      const vData = {
        Action: {
          Value: "Search",
        }
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
      };
      await axios
        .post(vURL, vData, vConfig)
        .then((vResponse) => {
          vStatus = vResponse.data.Status;
          
          for (var i = 0; i < vResponse.data.Message.length; i++) {
            vMessage.push(vResponse.data.Message[i]);
          }

          if (vStatus === 1) {
            SetResult(vResponse.data.Output.Result);
          }
        })
    }catch (vError) {
      vStatus = 2;
      vMessage.push("Server Problem");
    }
  }

  useEffect(() => {
    FuserSearch(1);
  }, []);

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className="nav-link" data-widget="pushmenu" role="button">
            <i className="fas fa-bars" />
          </Link>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown">
          <Link className="nav-link" data-toggle="dropdown">
            <i className="far fa-user-circle fa-lg"></i>
          </Link>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <Link className="dropdown-item">
            <table>
              <tbody>
                {vResult.map((vResultTemp) => (
                  <tr key={`${vResultTemp.Username}`}>
                    <td width="5px">Username :</td>
                    <td>{vResultTemp.Username}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table>
              <tbody>
                {vResult.map((vResultTemp) => (
                  <tr key={`${vResultTemp.GolfCourse}`}>
                    <td width="66px">Client</td>
                    <td> :{vResultTemp.GolfCourse}</td>
                  </tr>
                ))}
              </tbody>
            </table>
              {/* <div className="media">
                <h3 className="dropdown-item-title">Username:</h3>
                &nbsp;
                <div className="media-body">
                  <h3 className="dropdown-item-title">ADMIN_TR</h3>
                </div>
              </div>
              <div className="media">
                <h3 className="dropdown-item-title">
                  Client:
                </h3>
                &nbsp;
                <div className="media-body">
                  <h3 className="dropdown-item-title">TRAINING</h3>
                </div>
              </div> */}
            </Link>
          </div>
        </li>

        <li className="nav-item">
          <Link className="nav-link" data-widget="fullscreen" role="button">
            <i className="fas fa-expand-arrows-alt" />
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
