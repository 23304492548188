import React from 'react';
import Header from './Header';
import Menu from './Menu';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Menu />
      <div className="content-wrapper" style={{ padding: '0.5em' }}>
        {children}
      </div>
      <Footer />
    </>
  );
};

export default Layout;
