import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import toastr from "toastr";
import { useParams } from "react-router-dom";
import moment from "moment";
import DateTimePicker from "react-datetime-picker";
import { vAPIURL } from "../../Global";

const TeeTime = () => {
  const { vId } = useParams();
  const [vLoading, SetLoading] = useState(false);

  const [vActionList, SetActionList] = useState([]);
  const [vGolfGreenId, SetGolfGreenId] = useState();
  const [vGolfGreenIdList, SetGolfGreenIdList] = useState([]); //menyimpan list dropdown
  const [vGolfGreenIdListCode, SetGolfGreenIdListCode] = useState([]);
  const [vGolfGreenIdDisabled, SetGolfGreenIdDisabled] = useState();
  const [vTeeDateTimeValue, SetTeeDateTimeValue] = useState();
  const [vTeeDateTimeDisabled, SetTeeDateTimeDisabled] = useState();
  const [vTimeTypeValue, SetTimeTypeValue] = useState();
  const [vTimeTypeList, SetTimeTypeList] = useState([]);
  const [vTimeTypeDisabled, SetTimeTypeDisabled] = useState();

  const [vPriceValue, SetPriceValue] = useState();
  const [vPriceDisabled, SetPriceDisabled] = useState();
  const [vPriceRegex, SetPriceRegex] = useState();
  const [vPriceFormat, SetPriceFormat] = useState();

  const [vPriceUpValue, SetPriceUpValue] = useState();
  const [vPriceUpDisabled, SetPriceUpDisabled] = useState();
  const [vPriceUpRegex, SetPriceUpRegex] = useState();
  const [vPriceUpFormat, SetPriceUpFormat] = useState();

  const [vPriceForeignValue, SetPriceForeignValue] = useState();
  const [vPriceForeignDisabled, SetPriceForeignDisabled] = useState();

  const [vPriceForeignUpValue, SetPriceForeignUpValue] = useState();
  const [vPriceForeignUpDisabled, SetPriceForeignUpDisabled] = useState();

  const [vDescriptionValue, SetDescriptionValue] = useState();
  const [vDescriptionDisabled, SetDescriptionDisabled] = useState();
  const [vStatusValue, SetStatusValue] = useState();
  const [vStatusDisabled, SetStatusDisabled] = useState();

  const FTeeTime = useCallback(
    async (vAction) => {
      SetLoading(true);
      let vStatus = 1;
      let vMessage = [];

      let vIdNew = 0;

      try {
        const vURL = vAPIURL + "/TeeTime/" + vId;
        const vData = {
          Action: {
            Value: vAction,
          },
          Input: {
            GolfGreenId: {
              Value: vGolfGreenId,
            },
            TeeDatetime: {
              Value: vTeeDateTimeValue,
            },
            TimeType: {
              Value: vTimeTypeValue,
            },
            Price: {
              Value: vPriceValue,
            },
            PriceUp: {
              Value: vPriceUpValue,
            },
            PriceForeign: {
              Value: vPriceForeignValue,
            },
            PriceForeignUp: {
              Value: vPriceForeignUpValue,
            },
            Description: {
              Value: vDescriptionValue,
            },
            Status: {
              Value: vStatusValue,
            },
          },
        };
        const vConfig = {
          headers: {
            "Content-Type": "application/json",
            Authentication: `${localStorage.getItem("Authentication")}`,
          },
        };

        await axios
          .post(vURL, vData, vConfig)
          .then((vResponse) => {
            vStatus = vResponse.data.Status;

            for (var i = 0; i < vResponse.data.Message.length; i++) {
              vMessage.push(vResponse.data.Message[i]);
            }

            SetActionList(vResponse.data.Action.List);

            vIdNew = vResponse.data.Input.Id.Value;
            SetGolfGreenIdDisabled(vResponse.data.Input.GolfGreenId.Disabled);
            SetGolfGreenId(vResponse.data.Input.GolfGreenId.Value);
            SetGolfGreenIdList(vResponse.data.Input.GolfGreenId.List);
            SetGolfGreenIdListCode(vResponse.data.Input.GolfGreenId.ListCode);
            SetTeeDateTimeValue(vResponse.data.Input.TeeDatetime.Value);
            SetTeeDateTimeDisabled(vResponse.data.Input.TeeDatetime.Disabled);
            SetTimeTypeDisabled(vResponse.data.Input.TimeType.Disabled);
            SetTimeTypeValue(vResponse.data.Input.TimeType.Value);
            SetTimeTypeList(vResponse.data.Input.TimeType.List);
            
            SetPriceDisabled(vResponse.data.Input.Price.Disabled);
            SetPriceValue(vResponse.data.Input.Price.Value);
            SetPriceRegex(vResponse.data.Input.Price.Regex);
            SetPriceFormat(vResponse.data.Input.Price.Format);

            SetPriceUpDisabled(vResponse.data.Input.PriceUp.Disabled);
            SetPriceUpValue(vResponse.data.Input.PriceUp.Value);
            SetPriceUpRegex(vResponse.data.Input.PriceUp.Regex);
            SetPriceUpFormat(vResponse.data.Input.PriceUp.Format);
            
            SetPriceForeignDisabled(vResponse.data.Input.PriceForeign.Disabled);
            SetPriceForeignValue(vResponse.data.Input.PriceForeign.Value);
            
            SetPriceForeignUpDisabled(vResponse.data.Input.PriceForeignUp.Disabled);
            SetPriceForeignUpValue(vResponse.data.Input.PriceForeignUp.Value);
            
            SetDescriptionDisabled(vResponse.data.Input.Description.Disabled);
            SetDescriptionValue(vResponse.data.Input.Description.Value);
            SetStatusDisabled(vResponse.data.Input.Status.Disabled);
            SetStatusValue(vResponse.data.Input.Status.Value);
          })
          .catch((vError) => {
            vStatus = 2;
            vMessage.push("Server Problem");
            console.log(vError);
          });
      } catch (vError) {
        vStatus = 2;
        vMessage.push("Server Problem");
        console.log(vError);
      }

      if (vMessage.length > 0) {
        if (vStatus === 1) {
          toastr.success(vMessage.join("</br>"));
          // window.location.replace("/TeeTime/Search");
        } else {
          toastr.error(vMessage.join("</br>"));
        }
      }
        
      if (vStatus === 1 && vAction === "Create") {
        window.location.replace("/TeeTime/" + vIdNew); //if error vIdNew disable/delete
      }

      SetLoading(false);
    },
    [
      vDescriptionValue,
      vGolfGreenId,
      vId,
      vPriceForeignValue,
      vPriceForeignUpValue,
      vPriceValue,
      vPriceUpValue,
      vStatusValue,
      vTeeDateTimeValue,
      vTimeTypeValue,
    ]
  );

  // start commas handler
  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");
  // end commas handler
 
  const handleTeeDateTimeChange = (value) => {
    if (value instanceof Date && !isNaN(value)) {
      // convert to moment and format
      const momentDate = moment(value);
      SetTeeDateTimeValue(momentDate.format("DD-MMM-YYYY HH:mm"));
    }
  }; // instanceof adalah operator javascript seperti boolean yang mengembalikan nilai true or false

  useEffect(() => {
    FTeeTime("");
  }, []); //kalau ditambah ke dalam dependency FTeeTime malah ga bisa ubah field price & priceforeign

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="form-group">
              <label>Golf Course</label>
              <select
                id="golfGreenSelect"
                value={vGolfGreenId}
                onChange={(e) => SetGolfGreenId(e.target.value)}
                disabled={vGolfGreenIdDisabled === 1 ? "disabled" : ""}
                className="mt-1 ms-3"
              >
                {vGolfGreenIdListCode.map((optionValue, index) => (
                  <option key={index} value={vGolfGreenIdList[index]}>
                    {/*works*/}
                    {optionValue}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Tee Time Date</label>
              <DateTimePicker
                value={vTeeDateTimeValue?moment(vTeeDateTimeValue, "DD-MMM-YYYY HH:mm").toDate():null}
                onChange={handleTeeDateTimeChange}
                disabled={vTeeDateTimeDisabled === 1 ? "disabled" : ""}
                className="ms-1"
                format="dd-MMM-y HH:mm"
                clearIcon={null}
              />
            </div>
            <div className="form-group">
              <label>Time Type</label>
              <select
                value={vTimeTypeValue}
                onChange={(e) => SetTimeTypeValue(e.target.value)}
                disabled={vTimeTypeDisabled === 1 ? "disabled" : ""}
                className="ms-4"
              >
                {vTimeTypeList.map((optionValue, index) => (
                  <option key={index} value={optionValue}>
                    {optionValue}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Price</label>
              <input
                type="text"
                className="form-control"
                value={vPriceValue}
                onChange={(e) => SetPriceValue(addCommas(removeNonNumeric(e.target.value)))}
                disabled={vPriceDisabled === 1 ? "disabled" : ""}
                pattern={`(${vPriceRegex})|(${vPriceFormat})`}
              />
            </div>
            <div className="form-group">
              <label>Price Up</label>
              <input
                type="text"
                className="form-control"
                value={vPriceUpValue}
                onChange={(e) => SetPriceUpValue(addCommas(removeNonNumeric(e.target.value)))}
                disabled={vPriceUpDisabled === 1 ? "disabled" : ""}
                pattern={`(${vPriceUpRegex})|(${vPriceUpFormat})`}
              />
            </div>
            <div className="form-group">
              <label>Price Foreign</label>
              <input
                type="text"
                className="form-control"
                value={vPriceForeignValue}
                onChange={(e) => SetPriceForeignValue(addCommas(removeNonNumeric(e.target.value)))}
                disabled={vPriceForeignDisabled === 1 ? "disabled" : ""}
                pattern={`(${vPriceRegex})|(${vPriceFormat})`}
              />
            </div>
            <div className="form-group">
              <label>Price Foreign Up</label>
              <input
                type="text"
                className="form-control"
                value={vPriceForeignUpValue}
                onChange={(e) => SetPriceForeignUpValue(addCommas(removeNonNumeric(e.target.value)))}
                disabled={vPriceForeignUpDisabled === 1 ? "disabled" : ""}
                pattern={`(${vPriceRegex})|(${vPriceFormat})`}
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                type="text"
                className="form-control"
                value={vDescriptionValue}
                onChange={(e) => SetDescriptionValue(e.target.value)}
                disabled={vDescriptionDisabled === 1 ? "disabled" : ""}
              />
            </div>
            <div className="form-group">
              <label>Status</label>
              <input
                type="text"
                className="form-control"
                value={vStatusValue}
                onChange={(e) => SetStatusValue(e.target.value)}
                disabled={vStatusDisabled === 1 ? "disabled" : ""}
              />
            </div>
          </div>
          <div className="card-footer">
            {vLoading ? (
              <center>
                <i className="fas fa-1x fa-sync-alt fa-spin"></i>
              </center>
            ) : (
              vActionList.map((vActionListTemp, index) => (
                <>
                  <button
                    key={index}
                    className="btn btn-primary"
                    type="submit"
                    onClick={() => FTeeTime(vActionListTemp)}
                  >
                    {vActionListTemp}
                  </button>
                  &nbsp;
                </>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeeTime;
