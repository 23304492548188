import React, { useState, useEffect } from "react";
import axios from "axios";
import toastr from "toastr";
import { useParams } from "react-router-dom";
import { vAPIURL } from '../../Global';

const GolfCourse = () => {
  const { vId } = useParams();
  const [vLoading, SetLoading] = useState(false);

  const [vActionList, SetActionList] = useState([]);
  const [vNameValue, SetNameValue] = useState();
  const [vNameDisabled, SetNameDisabled] = useState();
  const [vShortNameValue, SetShortNameValue] = useState();
  const [vShortNameDisabled, SetShortNameDisabled] = useState();
  const [vDescriptionValue, SetDescriptionValue] = useState();
  const [vDescriptionDisabled, SetDescriptionDisabled] = useState();
  const [vNoteValue, SetNoteValue] = useState();
  const [vNoteDisabled, SetNoteDisabled] = useState();
  const [vStatusValue, SetStatusValue] = useState();
  const [vStatusDisabled, SetStatusDisabled] = useState();

  const FGolfCourse = async( vAction ) => {
    SetLoading(true);
    let vStatus = 1;
    let vMessage = [];

    let vIdNew = 0;

    try {
      const vURL =  vAPIURL + "/GolfCourse/" + vId;
      const vData = {
        Action: {
          Value: vAction,
        },
        Input: {
          Name: {
            Value: vNameValue,
          },
          ShortName: {
            Value: vShortNameValue,
          },
          Description: {
            Value: vDescriptionValue,
          },
          Note: {
            Value: vNoteValue,
          },
          Status: {
            Value: vStatusValue,
          },
        },
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
      };
      await axios
        .post(vURL, vData, vConfig)
        .then((vResponse) => {
          vStatus = vResponse.data.Status;

          for (var i = 0; i < vResponse.data.Message.length; i++) {
            vMessage.push(vResponse.data.Message[i]);
          }

          SetActionList(vResponse.data.Action.List);

          vIdNew = vResponse.data.Input.Id.Value;
          SetNameDisabled(vResponse.data.Input.Name.Disabled);
          SetNameValue(vResponse.data.Input.Name.Value);
          SetShortNameDisabled(vResponse.data.Input.ShortName.Disabled);
          SetShortNameValue(vResponse.data.Input.ShortName.Value);
          SetDescriptionDisabled(vResponse.data.Input.Description.Disabled);
          SetDescriptionValue(vResponse.data.Input.Description.Value);
          SetNoteDisabled(vResponse.data.Input.Note.Disabled);
          SetNoteValue(vResponse.data.Input.Note.Value);
          SetStatusDisabled(vResponse.data.Input.Status.Disabled);
          SetStatusValue(vResponse.data.Input.Status.Value);
        })
        .catch((vError) => {
          vStatus = 2;
          vMessage.push("Server Problem");
        });
    } catch (vError) {
      vStatus = 2;
      vMessage.push("Server Problem");
    }

    if (vMessage.length > 0) {
      if (vStatus === 1) {
        toastr.success(vMessage.join("</br>"));
      } else {
        toastr.error(vMessage.join("</br>"));
      }
    }

    if( vStatus === 1 && vAction === "Create" ){
      window.location.replace('Search');
    }

    SetLoading( false );
  };
  
  useEffect(
    () => {
      FGolfCourse( "" );
    }, []
  );

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="form-group">
              <label>Short Name</label>
              <input
                type="text"
                className="form-control"
                value={vShortNameValue}
                onChange={(e) => SetShortNameValue(e.target.value)}
                disabled={vShortNameDisabled === 1 ? "disabled" : ""}
              />
            </div>
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                value={vNameValue}
                onChange={(e) => SetNameValue(e.target.value)}
                disabled={vNameDisabled === 1 ? "disabled" : ""}
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                type="text"
                className="form-control"
                value={vDescriptionValue}
                onChange={(e) => SetDescriptionValue(e.target.value)}
                disabled={vDescriptionDisabled === 1 ? "disabled" : ""}
              />
            </div>
            <div className="form-group">
              <label>Note</label>
              <input
                type="text"
                className="form-control"
                value={vNoteValue}
                onChange={(e) => SetNoteValue(e.target.value)}
                disabled={vNoteDisabled === 1 ? "disabled" : ""}
              />
            </div>
            <div className="form-group">
              <label>Status</label>
              <input
                type="text"
                className="form-control"
                value={vStatusValue}
                onChange={(e) => SetStatusValue(e.target.value)}
                disabled={vStatusDisabled === 1 ? "disabled" : ""}
              />
            </div>
          </div>
          <div className="card-footer">
            {vLoading ? (
              <center>
                <i className="fas fa-1x fa-sync-alt fa-spin"></i>
              </center>
            ) : (
              vActionList.map((vActionListTemp) => (
                <>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={() => FGolfCourse( vActionListTemp ) }
                  >
                    {vActionListTemp}
                  </button>
                  &nbsp;
                </>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GolfCourse;
