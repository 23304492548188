import React, { useState, useEffect } from "react";
import { faSignOut, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { vAPIURL } from "../Global";
import axios from "axios";

const Menu = () => {
  const [vResult, SetResult] = useState([]);
  const FuserSearch = async ()=> {
    let vStatus = 1;
    let vMessage = [];
    try {
      const vURL = vAPIURL + "/UserInfo/Search";
      const vData = {
        Action: {
          Value: "Search",
        }
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
      };
      await axios
        .post(vURL, vData, vConfig)
        .then((vResponse) => {
          vStatus = vResponse.data.Status;
          
          for (var i = 0; i < vResponse.data.Message.length; i++) {
            vMessage.push(vResponse.data.Message[i]);
          }

          if (vStatus === 1) {
            SetResult(vResponse.data.Output.Result);
          }
        })
    }catch (vError) {
      vStatus = 2;
      vMessage.push("Server Problem");
    }
  }

  useEffect(() => {
    FuserSearch(1);
  }, []);

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <Link
        to="/"
        className="brand-link"
        style={{ textDecoration: "none", textAlign: "center" }}
      >
        <span className="brand-text font-weight-light">GM360 Dashboard</span>
      </Link>
      <div className="sidebar">
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <Link className="nav-link">
                <i className="far fa-user-circle fa-lg"></i>&nbsp;
                {vResult.map((vResultTemp, index) => (
                  <p key={index} className="text-warning">
                    Hallo {vResultTemp.Username}
                  </p>
                ))}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={(e) => window.location.replace("/GolfCourse/Search")}
                className="nav-link"
              >
                <p>
                  Golf Course
                </p>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                onClick={(e) => window.location.replace("/TeeTime/Search")}
              >
                <p>Tee Time</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                onClick={(e) => window.location.replace("/Booking/Search")}
              >
                <p>Booking</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                onClick={(e) => window.location.replace("/ConfirmationBooking")}
              >
                <p>Configuration</p>
              </Link>
              {/* <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link 
                      
                      className="nav-link"
                    >
                      <p>Configuration Email</p>
                      <i className="fas fa-angle-left right"></i>
                    </Link>
                  </li>
              </ul> */}
            </li>
          </ul>
        </nav>
        <div>
          <Link
            className="nav-link"
            onClick={(e) => window.location.replace("/Sign/Out")}
          >
            <FontAwesomeIcon
              icon={faSignOut}
              style={{ marginLeft: 5, color: "red", fontSize: "16px" }}
            />
            <span
              className="brand-text font-weight-light"
              style={{ marginLeft: 6, color: "red", fontSize: "16px" }}
            >
              Sign Out
            </span>
          </Link>
          <Link
            className="nav-link"
            onClick={(e) => window.location.replace("/Password")}
          >
            <FontAwesomeIcon
              icon={faLock}
              className="text-warning"
              style={{ marginLeft: 5, color: "red", fontSize: "16px" }}
            />
            <span
              className="brand-text font-weight-light text-warning"
              style={{ marginLeft: 6, color: "red", fontSize: "16px" }}
            >
              Change Password
            </span>
          </Link>
        </div>
      </div>
    </aside>
  );
};

export default Menu;
