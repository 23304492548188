import axios from "axios";
import React, { useState, useEffect } from "react";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { vAPIURL } from "../../Global";
import moment from "moment";
import DateTimePicker from "react-datetime-picker";
import { saveAs } from "file-saver";
import { DropdownButton, Dropdown } from "react-bootstrap";

const BookingSearchForm = () => {
  const [vLoading, SetLoading] = useState(false);
  const [vStartDateValue, SetStartDateValue] = useState();
  const [vEndDateValue, SetEndDateValue] = useState();
  const [vStatusIdValue, SetStatusIdValue] = useState();
  const [vStatusId, SetStatusId] = useState();
  const [vResult, SetResult] = useState([]);
  const [vTotalPageValue, SetTotalPageValue] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const FBookingSearch = async (vPage) => {
    SetLoading(true);
    let vStatus = 1;
    let vMessage = [];

    try {
      const vURL = vAPIURL + "/Booking/Search";
      const vData = {
        Action: {
          Value: "Search",
        },
        Input: {
          Page: {
            Value: vPage,
          },
          StartDate: {
            Value: vStartDateValue,
          },
          EndDate: {
            Value: vEndDateValue,
          },
          StatusId: {
            Value: vStatusIdValue,
          },
        },
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
      };
      await axios
        .post(vURL, vData, vConfig)
        .then((vResponse) => {
          vStatus = vResponse.data.Status;

          for (var i = 0; i < vResponse.data.Message.length; i++) {
            vMessage.push(vResponse.data.Message[i]);

            if (vStatus === 1) {
              const result = vResponse.data.Output.Result.map((item) => {
                return {
                  ...item,
                  Datetime: moment(item.Datetime, "DD-MMM--YYYY HH:mm").format(
                    "DD-MMM-YYYY' HH:mm"
                  ),
                  Tee: moment(item.Tee, "DD-MMM--YYYY HH:mm").format(
                    "DD-MMM-YYYY' HH:mm"
                  ),
                };
              });
              SetResult(result);
              SetTotalPageValue(vResponse.data.Output.TotalPage);
              SetStartDateValue(vResponse.data.Input.StartDate.Value);
              SetEndDateValue(vResponse.data.Input.EndDate.Value);
              SetStatusIdValue(vResponse.data.Input.StatusId.Value);
              SetStatusId(vResponse.data.Input.StatusId);
            }
          }
        })
        .catch((vError) => {
          vStatus = 2;
          vMessage.push("Server Problem");
        });
    } catch (vError) {
      vStatus = 2;
      vMessage.push("Server Problem");
    }

    if (vMessage.length > 0) {
      if (vStatus === 1) {
        //toastr.success( vMessage.join( "</br>" ) );
      } else {
        toastr.error(vMessage.join("</br>"));
      }
    }
    SetLoading(false);
    setCurrentPage(vPage);
  };

  const SummaryReport = async () => {
    SetLoading(true);

    let vMessage = [];

    try {
      const vURL = vAPIURL + "/Booking/Download";
      const vData = {
        Action: {
          Value: "SummaryReport",
        },
        Input: {
          StartDate: {
            Value: vStartDateValue,
          },
          EndDate: {
            Value: vEndDateValue,
          },
        },
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
        responseType: "blob",
      };
      const vResponse = await axios.post(vURL, vData, vConfig);

      const blob = new Blob([vResponse.data], { type: "text/csv" });

      saveAs(blob, "Summary.csv");

      for (var i = 0; i < vResponse.data.Message.length; i++) {
        vMessage.push(vResponse.data.Message[i]);
      }
    } catch (vError) {
      vMessage.push("Server Problem");
    }
    SetLoading(false);
  };

  const DetailReport = async () => {
    SetLoading(true);

    let vMessage = [];

    try {
      const vURL = vAPIURL + "/Booking/Download";
      const vData = {
        Action: {
          Value: "DetailReport",
        },
        Input: {
          StartDate: {
            Value: vStartDateValue,
          },
          EndDate: {
            Value: vEndDateValue,
          },
        },
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
        responseType: "blob",
      };
      const vResponse = await axios.post(vURL, vData, vConfig);

      const blob = new Blob([vResponse.data], { type: "text/csv" });

      saveAs(blob, "Detail.csv");

      for (var i = 0; i < vResponse.data.Message.length; i++) {
        vMessage.push(vResponse.data.Message[i]);
      }
    } catch (vError) {
      vMessage.push("Server Problem");
    }
    SetLoading(false);
  };

  // function IntToArray(vCount) {
  //   let IntToArray = [];
  //   for (let i = 1; i <= vCount; i++) IntToArray.push(i);
  //   return IntToArray;
  // }

  useEffect(() => {
    FBookingSearch(1);
  }, []);

  return (
    <section className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Search</h3>
          </div>
          <div className="card-body">
            <table>
              <tr>
                <td>
                  <label>Start Date &nbsp;</label>
                </td>
                <td>
                  <DateTimePicker
                    value={
                      vStartDateValue
                        ? moment(vStartDateValue, "DD-MMM-YYYY").toDate()
                        : moment()
                    }
                    onChange={(e) =>
                      SetStartDateValue(moment(e).format("DD-MMM-YYYY"))
                    }
                    format="dd-MMM-y"
                    clearIcon={null}
                  />
                  &nbsp;
                </td>
                <td>
                  <label>End Date &nbsp;</label>
                </td>
                <td style={{ marginBottom: "unset" }}>
                  <DateTimePicker
                    value={
                      vEndDateValue
                        ? moment(vEndDateValue, "DD-MMM-YYYY").toDate()
                        : moment()
                    }
                    onChange={(e) =>
                      SetEndDateValue(moment(e).format("DD-MMM-YYYY"))
                    }
                    format="dd-MMM-y"
                    clearIcon={null}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Status</label>
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  <select
                    value={vStatusIdValue}
                    onChange={(e) => SetStatusIdValue(e.target.value)}
                  >
                    {vStatusId?.List.map((vStatusIdValueTemp, index) => (
                      <option key={index} value={vStatusIdValueTemp}>
                        {vStatusId?.ListCode[index]}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            </table>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col-sm-1">
                <button
                  type="submit"
                  onClick={() => {
                    FBookingSearch(1);
                  }}
                  className="btn btn-primary"
                >
                  {vLoading ? (
                    <center>
                      <i className="fas fa-1x fa-sync-alt fa-spin"></i>
                    </center>
                  ) : (
                    "Search"
                  )}
                </button>
              </div>
              <div className="col-sm-1">
                {vLoading ? (
                  <center className="mt-2">
                    <i className="fas fa-1x fa-sync-alt fa-spin"></i>
                  </center>
                ) : (
                  <DropdownButton title="Report">
                    <Dropdown.Item
                      type="submit"
                      onClick={() => {
                        SummaryReport();
                      }}
                      eventKey="1"
                    >
                      CSV Detail Report
                    </Dropdown.Item>
                    <Dropdown.Item
                      type="submit"
                      onClick={() => {
                        DetailReport();
                      }}
                      eventKey="2"
                    >
                      CSV Summary Report
                    </Dropdown.Item>
                  </DropdownButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        {vLoading ? (
          <div className="card">
            <div className="card-body">
              <center>
                <i className="fas fa-1x fa-sync-alt fa-spin"></i>
              </center>
            </div>
          </div>
        ) : (
          <div className="card">
            <div className="card-body">
              <table className="table table-bordered">
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th>No</th>
                    <th>Date Time</th>
                    <th>Green</th>
                    <th>Date Tee Time</th>
                    <th>TimeType</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Description</th>
                    <th>TimeLimit</th>
                    <th>Status</th>
                    <th style={{ width: 30 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {vResult?.map((vResultTemp, index) => (
                    <tr key={index} style={{ textAlign: "center" }}>
                      <td>{vResultTemp.No}</td>
                      <td>{vResultTemp.Datetime}</td>
                      <td>{vResultTemp.Green}</td>
                      <td>{vResultTemp.Tee}</td>
                      <td>{vResultTemp.TimeType}</td>
                      <td>{vResultTemp.Name}</td>
                      <td>{vResultTemp.Email}</td>
                      <td>{vResultTemp.Phone}</td>
                      <td>{vResultTemp.Description}</td>
                      <td>{vResultTemp.TimeLimit}</td>
                      <td>{vResultTemp.Status}</td>
                      <td>
                        <button
                          className="page-link ms-1"
                          onClick={(e) =>
                            window.location.replace(
                              "/Booking/" + vResultTemp.Id + "/DetailSearch"
                            )
                          }
                        >
                          <center>
                            <i className="fas fa-search"></i>
                          </center>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <ul className="pagination pagination-sm m-0 float-right">
                <button
                  className="page-link"
                  onClick={() => FBookingSearch(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  &laquo;
                </button>
                {Array.from({ length: 5 }).map((_, index) => {
                  let pageNumber;
                  if (vTotalPageValue <= 5) {
                    pageNumber = index + 1;
                  } else {
                    if (currentPage <= 2) {
                      pageNumber = index + 1;
                    } else if (currentPage >= vTotalPageValue - 2) {
                      pageNumber = vTotalPageValue - 4 + index;
                    } else {
                      pageNumber = currentPage + index - 2;
                    }
                  }

                  if (pageNumber >= 1 && pageNumber <= vTotalPageValue) {
                    return (
                      <li
                        className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
                        key={index}
                      >
                        <button
                          className="page-link"
                          onClick={() => FBookingSearch(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
                <button
                  className="page-link"
                  onClick={() => FBookingSearch(currentPage + 1)}
                  disabled={currentPage === vTotalPageValue}
                >
                  &raquo;
                </button>
              </ul>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default BookingSearchForm;
