import React, { useState, useEffect } from "react";
import axios from "axios";
import "toastr/build/toastr.min.css";
import { useParams } from "react-router-dom";
import toastr from "toastr";
import { vAPIURL } from "../../Global";

const Booking = () => {
  const { vId } = useParams();
  const [vLoading, SetLoading] = useState(false);
  const [vData, SetData] = useState();
  const [vTotalPage, SetTotalPage] = useState(0);
  const [vHData, SetHData] = useState({});

  const FBooking = async (vPage) => {
    SetLoading(true);
    let vStatus = 1;
    let vMessage = [];

    try {
      const vURL = vAPIURL + "/Booking/" + vId + "/DetailSearch";
      const vData = {
        Action: {
          Value: "Search",
        },
        Input: {
          Page: {
            Value: vPage,
          },
        },
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
      };
      await axios
        .post(vURL, vData, vConfig)
        .then((vResponse) => {
          vStatus = vResponse.data.Status;

          for (var i = 0; i < vResponse.data.Message.length; i++) {
            vMessage.push(vResponse.data.Message[i]);
          }
          if (vStatus === 1) {
            SetData(vResponse.data.Output.Result);
            SetTotalPage(vResponse.data.Output.TotalPage);
          }
        })
        .catch((vError) => {
          vStatus = 2;
          vMessage.push("Server Problem");
        });
    } catch (vError) {
      vStatus = 2;
      vMessage.push("Server Problem");
    }
    if (vMessage.length > 0) {
      if (vStatus === 1) {
        //toastr.success( vMessage.join( "</br>" ) );
      } else {
        toastr.error(vMessage.join("</br>"));
      }
    }
    SetLoading(false);
  };

  const FBookingHead = async () => {
    SetLoading(true);

    try {
      const vURL = vAPIURL + "/Booking/" + vId;
      const vData = {
        Action: {
          Value: "",
        },
      };
      const vConfig = {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${localStorage.getItem("Authentication")}`,
        },
      };
      const vResponse = await axios.post(vURL, vData, vConfig);
      SetHData(vResponse.data);
    } catch (vError) {
      console.log(vError);
    }

    SetLoading(false);
  };

  function IntToArray(vCount) {
    let IntToArray = [];
    for (let i = 1; i <= vCount; i++) IntToArray.push(i);
    return IntToArray;
  }

  useEffect(() => {
    FBooking(1);
    FBookingHead(1);
  }, []);

  return (
    <>
      <section className="row">
        <div className="col-md-12">
          {vLoading ? (
            <div className="card">
              <div className="card-body">
                <center>
                  <i className="fas fa-1x fa-sync-alt fa-spin"></i>
                </center>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Booking Detail</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="d-flex align-items-center mt-1">
                      <i className="fas fa-user mr-1"></i>
                      <span className="ms-1">Name</span>
                      <div style={{ marginLeft: 99 }}>
                        :&nbsp;{vHData && vHData.Input?.Name?.Value}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <i className="far fa-calendar-alt mr-1"></i>
                      <span className="ms-1">Date Booking</span>
                      <div style={{ marginLeft: 53 }}>
                        :&nbsp;{vHData && vHData.Input?.Datetime?.Value}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <i className="far fa-calendar-alt mr-1"></i>
                      <span className="ms-1">Date Tee Time</span>
                      <div style={{ marginLeft: 50 }}>
                        :&nbsp;{vHData && vHData.Input?.Tee?.Value}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <i className="far fa-clock mr-1"></i>
                      <span className="ms-1">TimeType</span>
                      <div style={{ marginLeft: 77 }}>
                        :&nbsp;{vHData && vHData.Input?.TimeType?.Value}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <i className="fas fa-square mr-1"></i>
                      <span className="ms-1">Green</span>
                      <div
                      style={{marginLeft: 101}}
                      >:&nbsp;{vHData && vHData.Input?.Green?.Value}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex align-items-center">
                      <i className="fas fa-hourglass-end mr-1"></i>
                      <span className="ms-1">TimeLimit</span>
                      <div
                      style={{marginLeft: 80}}
                      >:&nbsp;{vHData && vHData.Input?.TimeLimit?.Value}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <i className="far fa-check-circle mr-1"></i>
                      <span className="ms-1">Status</span>
                      <div
                      style={{marginLeft: 100}}
                      >:&nbsp;{vHData && vHData.Input?.StatusCode?.Value}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <i className="fas fa-file-alt mr-1"></i>
                      <span className="ms-1">Description</span>
                      <div
                      style={{marginLeft: 71}}
                      >:&nbsp;{vHData && vHData.Description?.Datetime?.Value}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <i className="far fa-envelope mr-1"></i>
                      <span className="ms-1">Email</span>
                      <div
                      style={{marginLeft: 105}}
                      >:&nbsp;{vHData && vHData.Input?.Email?.Value}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <i className="fas fa-phone mr-1"></i>
                      <span className="ms-1">Phone</span>
                      <div
                      style={{marginLeft: 99}}
                      >:&nbsp;{vHData && vHData.Input?.Phone?.Value}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-12">
          {vLoading ? (
            <div className="card">
              <div className="card-body">
                <center>
                  <i className="fas fa-1x fa-sync-alt fa-spin"></i>
                </center>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-body">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Type</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vData &&
                      vData.map((vDataTemp, index) => (
                        <tr key={index}>
                          <td>{vDataTemp.No}</td>
                          <td>{vDataTemp.Name}</td>
                          <td>{vDataTemp.Phone}</td>
                          <td>{vDataTemp.Type}</td>
                          <td>{vDataTemp.Price}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="card-footer clearfix">
                <ul className="pagination pagination-sm m-0 float-right">
                  {IntToArray(vTotalPage).map((vTotalPageTemp, index) => (
                    <li className="page-item" key={index}>
                      <button
                        className="page-link"
                        onClick={() => FBooking(vTotalPageTemp)}
                      >
                        {vTotalPageTemp}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Booking;
